import React from "react";
import styled from "@emotion/styled";
import {StaticImage} from "gatsby-plugin-image";
import AnimateUp from "starkeydigital/React/Components/AnimateUp";
import MapMarker from "./mapMarker";
import MapMarkerSmaller from "./mapMarkerSmaller";
import Pages from "../data/pages";
interface props {}

const Container = styled.div`
  width: 100%;
  max-height: 375px;
  position: relative;
  margin-top: 120px;
`;
const Map: React.FC<props> = (props) => {
  return (
    <Container id="map">
      <AnimateUp>
        <StaticImage
          layout="fullWidth"
          alt="map"
          src="../images/map.png"
          quality={100}
        />
      </AnimateUp>

      <MapMarker
        left={38}
        top={-1}
        name="UK"
        participants={600}
        programs={25}
        orGreater={true}
      />

      <MapMarkerSmaller left={40.5} top={10} name="London" to="about-us" />

      <MapMarker
        left={45}
        top={7}
        name="Western Europe"
        participants={500}
        programs={20}
        orGreater={true}
      />

      <MapMarker
        left={55.5}
        top={4}
        name="Eastern Europe"
        participants={24}
        programs={3}
      />

      <MapMarker
        left={70}
        top={15}
        name="Asia"
        participants={50}
        programs={4}
      />

      <MapMarker
        left={24}
        top={0}
        name="New York"
        participants={16}
        programs={1}
      />

      <MapMarkerSmaller
        left={56}
        top={32}
        name="Dubai and Gulf States"
        link="/who-are-we/dubai/"
        length={10}
      />
    </Container>
  );
};
export default Map;
