import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  width: 315px;
  height: 285px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  color: #02578c;
  border-radius: 30px;
  transition: 200ms ease-in-out;
  padding: 2rem;
  padding-top: 50px;
  background: #fcfcfd;
  &:hover {
    background: #6ab2e2;
    color: white;
  }
`;

const Title = styled.div`
  font-family: Averta CY;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.025em;
`;

const Text = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.025em;
`;
interface props {
  title: string;
  text: string;
}
const IndexCard: React.FC<props> = (props) => {
  return (
    <Container className="icon-container">
      <div style={{width: "80px", height: "80px"}} className="icon-image">
        {props.children}
      </div>
      <Title>{props.title}</Title>
      <Text>{props.text}</Text>
    </Container>
  );
};
export default IndexCard;
