import React from "react";
import styled from "@emotion/styled";
import {StaticImage} from "gatsby-plugin-image";
import AnimateUp from "starkeydigital/React/Components/AnimateUp";
interface props {
  left: number;
  top: number;
  name: string;
  participants: number;
  programs: number;
  orGreater?: boolean;
}

const MapMarker: React.FC<props> = (props) => {
  const Container = styled.div`
    position: absolute;
    left: ${props.left}%;
    top: ${props.top}%;
  `;

  const Line = styled.div`
    height: 40px;
    background: #6ab2e2;
    width: 1px;
  `;

  return (
    <Container className="map-marker">
      <div style={{position: "relative"}}>
        <div className="map-marker-image">
          <AnimateUp>
            <StaticImage
              alt="marker"
              src="../images/map-marker.png"
              quality={100}
            />
          </AnimateUp>
        </div>
        <div className="map-marker-text">
          <h1>{props.name}</h1>
          <h2>
            Participants: {props.orGreater && ">"} {props.participants}
          </h2>
          <h2>
            Programmes: {props.orGreater && ">"}
            {props.programs}
          </h2>
          <Line></Line>
        </div>
      </div>
    </Container>
  );
};
export default MapMarker;
