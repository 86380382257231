import React from "react";
import useNumberIncrement from "starkeydigital/React/Hooks/useNumberIncrement";

interface props {
  number: number;
  text: string;
}
const IncrementingNumber: React.FC<props> = (props) => {
  const value = useNumberIncrement(props.number);
  return (
    <div className="col-md-3">
      <h1>{value}+</h1>
      <h2 style={{textAlign: "center"}}>{props.text}</h2>
    </div>
  );
};
export default IncrementingNumber;
