import {useState, useEffect} from "react";

function useNumberIncrement(
  endingNumber: number,
  duration: number = 2000,
  staringNumber: number = 0,
  useComma: boolean = true,
) {
  const [number, setNumber] = useState<number>(staringNumber);

  function numberWithCommas(x: number): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    let timeout;
    const timeoutTime = 50;
    const incrementCount =
      (endingNumber - staringNumber) * (timeoutTime / duration);
    let number = staringNumber;

    console.log(incrementCount);
    function incrementNumber() {
      if (number < endingNumber) {
        number += incrementCount;
        let result = Math.floor(number);
        if (result > endingNumber) result = endingNumber;

        setNumber(result);
        timeout = setTimeout(incrementNumber, timeoutTime);
      }
    }

    incrementNumber();

    return () => clearTimeout(timeout);
  }, []);

  return useComma ? numberWithCommas(number) : number.toString();
}

export default useNumberIncrement;
