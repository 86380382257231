import {StaticImage} from "gatsby-plugin-image";
import React from "react";
import Container from "../../../../StarkeyDigital/StarkeyDigital.Components/React/Components/container";
import Row from "../../../../StarkeyDigital/StarkeyDigital.Components/React/Components/row";
import HeaderDivideText from "../components/headerdividetext";
import IndexCard from "../components/indexCard";
import Layout from "../components/layout";
import styled from "@emotion/styled";
import Map from "../components/map";
import IncrementingNumber from "../components/incrementingNumber";
import AlisonProfile from "../components/alisonProfile";
interface props {}

const Index: React.FC<props> = (props) => {
  return (
    <Layout
      titleText="Welcome to atd partners."
      titleText2=""
      slogan="Our aim is to develop and release the potential of individuals, teams and the organisations they work in.
      We are experts in leadership coaching and women’s development & diversity."
    >
      <Container>
        <HeaderDivideText
          style={{marginTop: "115px"}}
          column="col-md-7"
          header="Developing Leaders and Teams around the World"
          text="We provide leadership and coaching programmes and board level
                        consulting for professional service firms and international
                        corporations worldwide."
        />
        <div id="map"></div>
        <Row justify="center" style={{margin: "50px 0"}}>
          <Map />
        </Row>

        <Row justify="center">
          <h1>
            Worldwide delivery. For professional
            <br />
            service firms and international corporations
          </h1>
        </Row>
        <Row justify="center" style={{marginTop: "35px"}}>
          <IncrementingNumber number={50} text="Clients" />
          <IncrementingNumber number={50} text="Programmes" />
          <IncrementingNumber number={2500} text="Professionals & Executives" />
        </Row>

        <HeaderDivideText
          style={{marginTop: "240px"}}
          column="col-md-10"
          columnText="col-md-7"
          header="Leadership Development. Delivering programmes, coaching and
                  consulting"
          text="We offer solutions to support the development and release the
                potential of individuals, teams and organisations they work in. We tailor our
                solutions to meet the client’s needs and deliver in a number of
                different ways:"
        />

        <Row justify="center">
          <div className="col-md-4 d-flex justify-content-center">
            <IndexCard
              title="Leadership Programmes"
              text="We design and deliver leadership programmes at board
                          and senior executive levels."
            >
              <StaticImage
                src="../images/icons/leadershipProgrammesFilledIn.png"
                alt="image"
                className="icon-image-darker"
                quality={100}
              />
            </IndexCard>
          </div>

          <div className="col-md-4 d-flex justify-content-center">
            <IndexCard
              title="Executive Coaching"
              text="We provide 1:1 coaching to senior leaders, high potential individuals and to early-career graduate entry executives and professionals. "
            >
              <StaticImage
                src="../images/icons/ExecutiveCoachingFilledIn.png"
                alt="image"
                quality={100}
              />
            </IndexCard>
          </div>

          <div className="col-md-4 d-flex justify-content-center">
            <IndexCard
              title="Board Consulting Services"
              text="Board meeting support, strategic consulting
              and conference speaking.  "
            >
              <StaticImage
                src="../images/icons/BoardConsultingFilledIn.png"
                alt="image"
                quality={100}
              />
            </IndexCard>
          </div>
        </Row>

        <div id="about-us"></div>
        <HeaderDivideText
          style={{marginTop: "180px"}}
          column="col-md-8"
          header="About atd partners"
          text="We always deliver work that reflects the client’s needs at both the individual and organisational level. We are recognised for designing and delivering award winning senior executive development and coaching programmes.  Led by Alison Temperley, we have a number of clients for whom we deliver annual development programmes and regular coaching work and we have developed a very experienced team of coaches who support us in our work.
            <br/> <br/>    We are based near London and also operate from Brighton where we
            deliver our virtual work, but we also work from client premises
            and venues in London and across the UK and internationally, with
            regular work across Europe and also in Asia, New York and South
            Africa.
                  "
        />

        <AlisonProfile />
        <HeaderDivideText
          style={{marginTop: "225px"}}
          column="col-md-8"
          header="Extensive Experience"
          text="With over 20 years’ experience of leadership development and executive coaching, we have had the pleasure of working with many client organisations with a focus on the professional services, financial and IT services sectors. We have also worked with non-profit-making public sector organisations and charities."
        />

        <Row justify="center" style={{marginTop: "40px"}}>
          <div style={{maxWidth: "600px"}}>
            <StaticImage
              src="../images/clients.png"
              alt="clients"
              quality={90}
            />
          </div>

          {/* <Divider /> */}
        </Row>
      </Container>
    </Layout>
  );
};
export default Index;

const Divider = styled.div`
  width: 1120px;
  height: 1px;
  margin-top: 100px;
  margin-bottom: 100px;
  background: #e6e8ec;
`;
