import React, {Ref} from "react";
import styled from "@emotion/styled";
import {StaticImage} from "gatsby-plugin-image";
import AnimateUp from "starkeydigital/React/Components/AnimateUp";
import Link from "./link";
interface props {
  left: number;
  top: number;
  name: string;
  to?: any;
  link?: string;
  length?: number;
}

const MapMarkerSmaller: React.FC<props> = (props) => {
  const Container = styled.div`
    position: absolute;
    left: ${props.left}%;
    top: ${props.top}%;
    cursor: pointer;
  `;

  const Line = styled.div`
    height: ${props.length ?? 40}px;
    background: #6ab2e2;
    width: 1px;
  `;

  const handleClick = () => {
    console.log(props.to);
    if (props.to) {
      const element = document.getElementById(props.to);
      if (element) {
        element.scrollIntoView({behavior: "smooth", block: "start"});
      }
    }
  };

  return (
    <Container className="map-marker-smaller">
      <div style={{position: "relative"}}>
        <div className="map-marker-image-small" onClick={handleClick}>
          <AnimateUp>
            {props.link ? (
              <Link to={props.link}>
                <StaticImage
                  alt="marker"
                  src="../images/map-marker-small.png"
                  quality={100}
                />
              </Link>
            ) : (
              <StaticImage
                alt="marker"
                src="../images/map-marker-small.png"
                quality={100}
              />
            )}
          </AnimateUp>
        </div>
        <div className="map-marker-text-small">
          <h1>{props.name}</h1>
          <Line></Line>
        </div>
      </div>
    </Container>
  );
};
export default MapMarkerSmaller;
