import styled from "@emotion/styled";
import {StaticImage} from "gatsby-plugin-image";
import React from "react";
import Row from "starkeydigital/React/Components/row";
import HeaderDivideTextLeft from "./headerDivideTextLeft";
export default () => {
  const AlisonText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.025em;
    color: #02578c;
  `;

  const AlisonTextHeader = styled.div`
    font-family: Averta CY;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: #02578c;
  `;

  return (
    <Row style={{marginTop: "120px"}}>
      <div className="col-md-4">
        <StaticImage src="../images/alison.png" alt="image" quality={90} />

        <AlisonTextHeader style={{marginTop: "20px"}}>
          Alison's Qualifications
        </AlisonTextHeader>

        <AlisonText style={{marginTop: "20px"}}>
          MA in Consulting to the Organisation - <br /> A Psychodynamic Approach
          <br />
          <br />
          Member of the Institute of Chartered Accountants in England and Wales
          <br />
          <br />
          Honours Degree in Economics
          <br />
          <br />
          Member of the Association for Coaching
        </AlisonText>
      </div>

      <div className="col-md-8 mt-5 mt-md-0">
        <HeaderDivideTextLeft
          column="col"
          header="The Founder and     <br/> Managing Partner, Alison"
          text="Alison founded the business over 20 years ago after leaving PwC and taking a short career break to look after her young children.  Retaining contact with PwC and taking on work at Cranfield Business School eventually led her to work independently and grow the business with a group of coaches working closely with her as the client list grew.  atd’s range of clients is broad, working with large international law, consulting and accountancy firms and global banks, as well as other international corporates and smaller private companies and charities.
            <br/> <br/>

            Alison has an MA in organisational psychology from the Tavistock and is a member of the Association for Coaching. She is an accredited tutor for the Centaur model, a qualified MBTI practitioner and sits as a lay member on the British Psychoanalytic Council’s ethics committee. After taking an Economics degree Alison qualified as a Chartered Accountant with PwC.
          "
        />
      </div>
    </Row>
  );
};
