import React from "react";
import Row from "../../../../StarkeyDigital/StarkeyDigital.Components/React/Components/row";
import AnimateUp from "starkeydigital/React/Components/AnimateUp";

interface props {
  style?: React.CSSProperties;
  column: string;
  columnText?: string;
  header: string;
  text?: string;
}
const HeaderDivideText: React.FC<props> = (props) => {
  let columnText = props.columnText ?? props.column;
  return (
    <>
      <Row justify="center" style={props.style}>
        <div className={`${props.column} justify-content-center`}>
          <AnimateUp>
            <h1>{props.header}</h1>
          </AnimateUp>
        </div>
      </Row>
      <Row justify="center">
        <div className={`${columnText} justify-content-center`}>
          <AnimateUp>
            <div
              className="divider"
              style={{margin: "auto", marginTop: "35px"}}
            />
          </AnimateUp>

          {props.text && (
            <AnimateUp>
              <p
                style={{marginTop: "35px", textAlign: "center"}}
                dangerouslySetInnerHTML={{__html: props.text}}
              />
            </AnimateUp>
          )}
        </div>
      </Row>
    </>
  );
};
export default HeaderDivideText;
